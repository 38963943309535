import React, { useCallback, useEffect, useState } from 'react';
import { useBreakpointValue, useTheme } from '@chakra-ui/react';
import {
    trackClientEventFromPriceSheetItems,
    useClientEventApiFetch,
    useEvent,
    useFetchPriceSheetDataByImage
} from 'ts/client/common';
import {
    getPriceSheetGroupsFromPriceSheetItemsAndBoundItems,
    getSpFulfilledDigitalBoundItemsFromProductGroups
} from 'ts/client/gallery';
import { EditCropSlide } from 'ts/client/gallery/components';
import { hasMultipleSubgroupsOfProducts } from 'ts/client/gallery/components/common';
import { ISPPhotoService } from 'ts/client/types';
import { SlidesSidePanel, noop } from 'ts/common';
import type { ICrop } from 'ts/common/types';
import {
    AddedToCartSlide,
    CardTemplatePreviewSlide,
    EmailCaptureAddToCartSlide,
    EmailCaptureCardDesignerSlide,
    SelectCardSlide,
    SelectCardTemplateSlide,
    SelectPackageSlide,
    SelectProductGroupSlide,
    SelectProductOptionsSlide,
    SelectProductSlide
} from './add-to-cart-side-panel';
import PricingAndDiscountsSlide from './add-to-cart-side-panel/PricingAndDiscountsSlide';
import { groupHasLabFulfilledCards } from './common';
import type { IBound, IBoundItem, IImage, IPathContext } from './types';

interface IAddToCartSidePanelProps {
    brandCurrencyCode: string;
    getPathContext: () => IPathContext;
    getPhotoUrl: ISPPhotoService['getUrl'];
    isSubjectToGdpr: boolean;
    selectPackage: (
        selectedPackage: SpApi.Client.IPriceSheetItem,
        selectedPhoto: IImage,
        isSmallScreen: boolean
    ) => void;
    setIsAddToCartSidePanelOpen: (isOpen: boolean) => void;
    withSinglePhotoOpen: (open: (image: IImage) => void) => void;
    withAllPhotosOpen: (openBuyingAllPhotos: (images: IImage[]) => void) => void;
    updateUserCartState: (numCartItems: number) => void;
}

const AddToCartSidePanel: React.FC<IAddToCartSidePanelProps> = ({
    brandCurrencyCode,
    getPathContext,
    getPhotoUrl,
    isSubjectToGdpr,
    selectPackage,
    setIsAddToCartSidePanelOpen,
    withSinglePhotoOpen,
    withAllPhotosOpen,
    updateUserCartState
}) => {
    const controlSubscribe = useCallback((goToSlide, goToPreviousSlide) => {
        setGoToSlide(() => goToSlide);
        setGoToPreviousSlide(() => goToPreviousSlide);
    }, []);
    const [previousSlideOnHistory, setPreviousSlideOnHistory] = useState<Nullable<number>>();
    const [trackingData, setTrackingData] = useState<{
        buyAllImagesState: IImage[];
        digitalItem?: Nullable<IBoundItem>;
    }>({
        buyAllImagesState: []
    });
    const [crop, setCrop] = useState<Nullable<ICrop>>(null);

    const [goToSlide, setGoToSlide] = useState<(index: number) => void>(noop);
    const [goToPreviousSlide, setGoToPreviousSlide] = useState<() => void>(noop);
    const [image, setImage] = useState<IImage>(blankImage);
    const [images, setImages] = useState<IImage[]>([]);
    const [buyAllImagesAddedToCart, setBuyAllImagesAddedToCart] = useState<IImage[]>([]);
    const isBuyingAllPhotos = !!images.length;
    const [isOpen, setIsOpen] = useState(false);
    const [priceSheetGroups, setPriceSheetGroups] = useState<SpApi.Client.IPriceSheetGroup[]>([]);
    const [digitalOrderPrice, setDigitalOrderPrice] = useState<number>();
    const [priceSheetItemAddedToCart, setPriceSheetItemAddedToCart] =
        useState<SpApi.Client.IPriceSheetItem>();
    const [priceSheetItemIdAddedToCart, setPriceSheetItemIdAddedToCart] = useState<number>();
    const {
        id: eventId,
        settings: { priceSheetShowPackagesOnly }
    } = useEvent();
    const [selectedCardCategory, setSelectedCardCategory] = useState<IBound>();
    const [selectedCardProduct, setSelectedCardProduct] = useState<IBoundItem>();
    const [selectedPriceSheetGroup, setSelectedPriceSheetGroup] =
        useState<SpApi.Client.IPriceSheetGroup>();
    const [selectedPriceSheetItemForEditCropSlide, setSelectedPriceSheetItemForEditCropSlide] =
        useState<Nullable<SpApi.Client.IPriceSheetItem>>(null);
    const [selectedPriceSheetGroupForOptionsSlide, setSelectedPriceSheetGroupForOptionsSlide] =
        useState<Nullable<SpApi.Client.IPriceSheetGroup>>(null);
    const [selectedPriceSheetItem, setSelectedPriceSheetItem] =
        useState<Nullable<SpApi.Client.IPriceSheetItem>>(null);
    const [quantityAddedToCart, setQuantityAddedToCart] = useState(1);
    const { response } =
        useClientEventApiFetch<IApiListResponse<SpApi.Client.IPriceSheetItem>>('price-sheet/item');

    const { context, contextId } = getPathContext();

    const { isFetchingPriceSheetDataByImage, priceSheetDataByImage } =
        useFetchPriceSheetDataByImage(image, isOpen, isBuyingAllPhotos, context, contextId);

    const isDigitalDownload =
        !!selectedPriceSheetItem &&
        selectedPriceSheetItem.group.groupType === 'digitals' &&
        selectedPriceSheetItem.type === 'price-sheet-item-digital' &&
        selectedPriceSheetItem.isDigitalDownload;

    const isMediumScreenOrLarger = useBreakpointValue({ base: false, md: true });
    const packagesData =
        priceSheetGroups.length >= 1
            ? priceSheetGroups.find((pkg) => {
                  return pkg.groupType === 'packages';
              })
            : undefined;

    const skipSelectProductGroupSlide =
        !isFetchingPriceSheetDataByImage &&
        priceSheetDataByImage !== null &&
        priceSheetGroups.length === 1;
    const shouldHidePreviousButton =
        isFetchingPriceSheetDataByImage ||
        (skipSelectProductGroupSlide && previousSlideOnHistory === SlideIndex.SelectProductGroup);

    const pushGoogleAnalyticsDataCallback = useCallback(
        (
            quantity: number,
            orderPrice?: number,
            priceSheetItem?: SpApi.Client.IPriceSheetItem,
            buyAllImagesState?: IImage[],
            digitalItem?: Nullable<IBoundItem>,
            eventSlug?: string
        ) => {
            const priceSheetItemsToTrack =
                isBuyingAllPhotos && !isDigitalDownload && priceSheetItem && buyAllImagesState
                    ? Array(buyAllImagesState.length).fill(priceSheetItem)
                    : priceSheetItem
                    ? [priceSheetItem]
                    : undefined;

            trackClientEventFromPriceSheetItems({
                eventSlug: eventSlug ? eventSlug : 'add_to_cart',
                eventId,
                priceSheetItems: priceSheetItemsToTrack,
                orderPrice,
                quantity,
                currencyCode: brandCurrencyCode,
                digitalItem
            });
        },
        [brandCurrencyCode, eventId, isBuyingAllPhotos, isDigitalDownload]
    );

    const addToCart = (
        priceSheetItemId: number,
        quantity: number,
        buyAllImagesState: IImage[],
        orderPrice?: number,
        digitalItem?: Nullable<IBoundItem>
    ) => {
        setPriceSheetItemIdAddedToCart(priceSheetItemId);
        setQuantityAddedToCart(quantity);

        const priceSheetItem =
            selectedPriceSheetItem ??
            selectedPriceSheetGroup?.items.find(({ id }) => id === priceSheetItemId);

        if (buyAllImagesState) {
            setBuyAllImagesAddedToCart(buyAllImagesState);
        }

        const isAllPhotosDigitalItem =
            digitalItem && (digitalItem.is_event || digitalItem.is_album) ? true : false;
        const calculatedQuantity =
            !!buyAllImagesState.length && !isAllPhotosDigitalItem
                ? quantity * buyAllImagesState.length
                : quantity;
        updateUserCartState(calculatedQuantity);

        pushGoogleAnalyticsDataCallback(
            quantity,
            orderPrice,
            priceSheetItem,
            buyAllImagesState,
            digitalItem
        );
        setPriceSheetItemAddedToCart(priceSheetItem);
        goToSlide(SlideIndex.AddedToCart);
    };

    useEffect(() => {
        withSinglePhotoOpen((image) => {
            setImage(image);
            setIsOpen(true);
            setIsAddToCartSidePanelOpen(true);
        });
    }, [setIsOpen, withSinglePhotoOpen, setIsAddToCartSidePanelOpen]);

    useEffect(() => {
        withAllPhotosOpen((images) => {
            setImages(images);
            setIsOpen(true);
            setIsAddToCartSidePanelOpen(true);
        });
    }, [setIsOpen, withAllPhotosOpen, setIsAddToCartSidePanelOpen]);

    const goToSelectProductOptionsSlide = useCallback(() => {
        setSelectedPriceSheetItemForEditCropSlide(null);
        setCrop(null);
        goToSlide(SlideIndex.SelectProductOptions);
    }, [goToSlide]);

    const goToSelectProductOptionsSlideWithGroup = useCallback(
        (priceSheetGroup: SpApi.Client.IPriceSheetGroup) => {
            const { items } = priceSheetGroup;

            if (items.length > 1) {
                setSelectedPriceSheetGroupForOptionsSlide(priceSheetGroup);
                setSelectedPriceSheetItem(null);
            } else {
                setSelectedPriceSheetGroupForOptionsSlide(null);
                setSelectedPriceSheetItem(items[0]);
            }

            goToSelectProductOptionsSlide();
        },
        [goToSelectProductOptionsSlide]
    );

    const goToSelectProductOptionsSlideWithItem = useCallback(
        (priceSheetItem: SpApi.Client.IPriceSheetItem) => {
            setSelectedPriceSheetGroupForOptionsSlide(null);
            setSelectedPriceSheetItem(priceSheetItem);
            goToSelectProductOptionsSlide();
        },
        [goToSelectProductOptionsSlide]
    );

    const goToPricingAndDiscountsSlide = () => {
        goToSlide(SlideIndex.PricingAndDiscountsSlide);
    };

    const handleClose = () => {
        setIsOpen(false);
        setIsAddToCartSidePanelOpen(false);
        setImages([]);
        setCrop(null);
        setDigitalOrderPrice(undefined);
        setPriceSheetItemIdAddedToCart(undefined);
        setTrackingData({
            buyAllImagesState: []
        });
    };

    const handleProductGroupSelection = useCallback(
        (priceSheetGroup: SpApi.Client.IPriceSheetGroup) => {
            setSelectedPriceSheetGroup(priceSheetGroup);

            if (
                isBuyingAllPhotos &&
                priceSheetGroup.groupType !== 'packages' &&
                priceSheetGroup.groupType !== 'cards' &&
                !hasMultipleSubgroupsOfProducts(priceSheetGroup)
            ) {
                goToSelectProductOptionsSlideWithGroup(priceSheetGroup);
            } else if (
                priceSheetGroup.groupType === 'cards' &&
                groupHasLabFulfilledCards(priceSheetGroup)
            ) {
                goToSlide(SlideIndex.SelectCard);
            } else if (priceSheetGroup.groupType === 'packages') {
                goToSlide(SlideIndex.SelectPackageSlide);
            } else if (hasMultipleSubgroupsOfProducts(priceSheetGroup)) {
                goToSlide(SlideIndex.SelectProduct);
            } else {
                goToSelectProductOptionsSlideWithGroup(priceSheetGroup);
            }
        },
        [goToSlide, goToSelectProductOptionsSlideWithGroup, isBuyingAllPhotos]
    );

    useEffect(() => {
        if (response) {
            const spFulfilledDigitalBoundItems = getSpFulfilledDigitalBoundItemsFromProductGroups(
                priceSheetDataByImage?.groups ?? []
            );

            const priceSheetGroups = getPriceSheetGroupsFromPriceSheetItemsAndBoundItems(
                response.items,
                spFulfilledDigitalBoundItems,
                priceSheetShowPackagesOnly
            );

            setPriceSheetGroups(priceSheetGroups);
        }
    }, [priceSheetShowPackagesOnly, priceSheetDataByImage, response]);

    const theme = useTheme();

    return (
        <SlidesSidePanel
            controlSubscribe={controlSubscribe}
            isDarkTheme={!theme.isLightColorScheme}
            isOpen={isOpen}
            onClose={handleClose}
            setPreviousSlideOnHistory={setPreviousSlideOnHistory}
            slides={[
                {
                    content: (
                        <SelectProductGroupSlide
                            priceSheetDataByImage={priceSheetDataByImage}
                            getPathContext={getPathContext}
                            goToPricingAndDiscountsSlide={goToPricingAndDiscountsSlide}
                            image={image}
                            images={images}
                            isFetchingPriceSheetDataByImage={isFetchingPriceSheetDataByImage}
                            onProductGroupSelected={handleProductGroupSelection}
                            priceSheetGroups={priceSheetGroups}
                        />
                    )
                },
                {
                    content: (
                        <SelectCardSlide
                            onCardCategorySelected={(cardCategory: IBound) => {
                                setSelectedCardCategory(cardCategory);
                                goToSlide(SlideIndex.SelectCardTemplate);
                            }}
                        />
                    )
                },
                {
                    content: (
                        <SelectCardTemplateSlide
                            cardCategory={selectedCardCategory}
                            onCardProductSelected={(cardProduct: IBoundItem) => {
                                const cardPricesheetItems = selectedPriceSheetGroup?.items ?? [];
                                const cardPricesheetItem = cardPricesheetItems?.find(
                                    (item) => item.id === cardProduct?.priceSheetItemId
                                );

                                trackClientEventFromPriceSheetItems({
                                    eventSlug: 'view_item',
                                    eventId,
                                    priceSheetItems: cardPricesheetItem
                                        ? [cardPricesheetItem]
                                        : undefined,
                                    orderPrice: cardProduct?.whcc_id
                                        ? parseFloat(cardProduct?.price) * 25
                                        : parseFloat(cardProduct?.price),
                                    quantity: cardProduct?.whcc_id ? 25 : 1,
                                    currencyCode: brandCurrencyCode
                                });
                                setSelectedCardProduct(cardProduct);
                                goToSlide(SlideIndex.CardTemplatePreview);
                            }}
                            previouslySelectedCardProduct={selectedCardProduct}
                        />
                    )
                },
                {
                    content: (
                        <CardTemplatePreviewSlide
                            brandCurrencyCode={brandCurrencyCode}
                            categoryName={selectedCardCategory?.bounds_name}
                            cardProduct={selectedCardProduct}
                            getPathContext={getPathContext}
                            goToEmailCaptureSlide={() => {
                                goToSlide(SlideIndex.EmailCaptureCardDesignerSlide);
                            }}
                            image={isBuyingAllPhotos ? images[0] : image}
                            cardPricesheetItems={selectedPriceSheetGroup?.items ?? []}
                        />
                    )
                },
                {
                    content: (
                        <EmailCaptureCardDesignerSlide
                            getPathContext={getPathContext}
                            cardProduct={selectedCardProduct}
                            image={isBuyingAllPhotos ? images[0] : image}
                            isSubjectToGdpr={isSubjectToGdpr}
                        />
                    )
                },
                {
                    content: (
                        <SelectProductSlide
                            getPathContext={getPathContext}
                            image={isBuyingAllPhotos ? images[0] : image}
                            isBuyingAllPhotos={isBuyingAllPhotos}
                            onProductGroupSelected={goToSelectProductOptionsSlideWithGroup}
                            onProductSelected={goToSelectProductOptionsSlideWithItem}
                            priceSheetGroup={selectedPriceSheetGroup}
                        />
                    ),
                    hidePreviousButton: shouldHidePreviousButton
                },
                {
                    content: (
                        <SelectPackageSlide
                            image={isBuyingAllPhotos ? images[0] : image}
                            onPackageSelected={(selectedPackage, image) => {
                                // TODO: FOR-9304 - Skip package flow when handling all gallery digitals
                                const isSmallScreen = !isMediumScreenOrLarger;
                                selectPackage(selectedPackage, image, isSmallScreen);
                                handleClose();
                            }}
                            packages={selectedPriceSheetGroup?.items ?? []}
                        />
                    ),
                    hidePreviousButton: shouldHidePreviousButton
                },
                {
                    content: (
                        <SelectProductOptionsSlide
                            addToCart={addToCart}
                            crop={crop}
                            setDigitalOrderPrice={setDigitalOrderPrice}
                            setTrackingData={setTrackingData}
                            getPathContext={getPathContext}
                            getPhotoUrl={getPhotoUrl}
                            goToEmailCaptureAddToCartSlide={(priceSheetItemId, quantity, crop) => {
                                setPriceSheetItemIdAddedToCart(priceSheetItemId);
                                setQuantityAddedToCart(quantity);
                                setCrop(crop);
                                goToSlide(SlideIndex.EmailCaptureAddToCartSlide);
                            }}
                            image={image}
                            buyAllImages={images}
                            lastCroppedPriceSheetItem={selectedPriceSheetItemForEditCropSlide}
                            onCropEdit={(
                                quantity,
                                crop,
                                selectedPriceSheetItemForEditCropSlide
                            ) => {
                                setQuantityAddedToCart(quantity);
                                setCrop(crop);
                                setSelectedPriceSheetItemForEditCropSlide(
                                    selectedPriceSheetItemForEditCropSlide
                                );
                                goToSlide(SlideIndex.EditCrop);
                            }}
                            priceSheetData={priceSheetDataByImage}
                            priceSheetGroup={selectedPriceSheetGroupForOptionsSlide}
                            priceSheetItem={selectedPriceSheetItem}
                            pushGoogleAnalyticsDataCallback={pushGoogleAnalyticsDataCallback}
                        />
                    ),
                    hidePreviousButton: shouldHidePreviousButton,
                    scrollToTopOnRender: true
                },
                {
                    content: (
                        <EditCropSlide
                            crop={crop}
                            image={image}
                            onCropSet={(crop) => {
                                crop.isChanged = true;

                                setCrop(crop);
                                goToPreviousSlide();
                            }}
                            priceSheetItem={selectedPriceSheetItemForEditCropSlide}
                        />
                    ),
                    size: isMediumScreenOrLarger ? 'full' : 'lg'
                },
                {
                    content: (
                        <EmailCaptureAddToCartSlide
                            addToCart={addToCart}
                            buyAllImages={images}
                            crop={crop}
                            digitalOrderPrice={digitalOrderPrice}
                            getPathContext={getPathContext}
                            image={image}
                            isSubjectToGdpr={isSubjectToGdpr}
                            priceSheetItemId={priceSheetItemIdAddedToCart}
                            quantity={quantityAddedToCart}
                            trackingData={trackingData}
                        />
                    )
                },
                {
                    content: (
                        <AddedToCartSlide
                            crop={crop}
                            digitalOrderPrice={digitalOrderPrice}
                            buyAllImages={buyAllImagesAddedToCart}
                            item={priceSheetItemAddedToCart}
                            priceSheetData={priceSheetDataByImage}
                            priceSheetGroup={selectedPriceSheetGroup}
                            priceSheetItemId={priceSheetItemIdAddedToCart}
                            image={image}
                            quantity={quantityAddedToCart}
                            onKeepShoppingClick={() => {
                                setPriceSheetItemIdAddedToCart(undefined);
                                setDigitalOrderPrice(undefined);
                                setCrop(null);
                                setBuyAllImagesAddedToCart([]);
                                setTrackingData({
                                    buyAllImagesState: []
                                });
                                goToSlide(SlideIndex.SelectProductGroup);
                            }}
                        />
                    ),
                    hidePreviousButton: true
                },
                {
                    content: (
                        <PricingAndDiscountsSlide
                            packagesData={packagesData}
                            pricingAndDiscountsData={priceSheetDataByImage}
                        />
                    )
                }
            ]}
        />
    );
};

enum SlideIndex {
    SelectProductGroup = 0,
    SelectCard = 1,
    SelectCardTemplate = 2,
    CardTemplatePreview = 3,
    EmailCaptureCardDesignerSlide = 4,
    SelectProduct = 5,
    SelectPackageSlide = 6,
    SelectProductOptions = 7,
    EditCrop = 8,
    EmailCaptureAddToCartSlide = 9,
    AddedToCart = 10,
    PricingAndDiscountsSlide = 11
}

const blankImage = { filterBlackAndWhite: false, id: 0, height: 0, url: '', width: 0 };

export default AddToCartSidePanel;

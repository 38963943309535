import '../../common/js/jquery-2.1.4';
import '../v2_common_lib/lib/zeroclipboard-2.2.0/dist/ZeroClipboard';
import '../common/lib/modernizr-3.5.0-custom';
import '../v2_common_lib/js/polyfill';
import '../v2_common_lib/js/client.legacy';
import angular from 'angular';
import { react2angular } from 'react2angular';
import ComparePage from 'client_react/gallery/pages/ComparePage';
import AdminModal from 'client_react/gallery/components/AdminModal';
import AlbumList from 'client_react/gallery/components/AlbumList';
import AlbumPasswordForm from 'client_react/gallery/components/AlbumPasswordForm';
import StudioMessage from 'client_react/gallery/components/StudioMessage';
import BundleFileList from 'client_react/gallery/components/BundleFileList';
import CollectEmailCredentialsModal from 'client_react/gallery/components/CollectEmailCredentialsModal';
import CompareHeader from 'client_react/gallery/components/CompareHeader';
import DigitalDownloadEmailCredentialsModal from 'client_react/gallery/components/DigitalDownloadEmailCredentialsModal';
import DigitalDownloadPinForm from 'client_react/gallery/components/DigitalDownloadPinForm';
import DigitalDownloadRulesForm from 'client_react/gallery/components/DigitalDownloadRulesForm';
import DigitalDownloadRulesHeader from 'client_react/gallery/components/DigitalDownloadRulesHeader';
import EmptyCartModal from 'client_react/gallery/components/EmptyCartModal';
import ExitPackageBuilderModal from 'client_react/gallery/components/ExitPackageBuilderModal';
import LabelList from 'client_react/gallery/components/LabelList';
import LabelModalFooter from '../../client_react/gallery/components/LabelModalFooter';
import LabelModalHeader from '../../client_react/gallery/components/LabelModalHeader';
import PackageAddToCartModal from 'client_react/gallery/components/PackageAddToCartModal';
import PhotoHeader from 'client_react/gallery/components/PhotoHeader';
import SocialLinks from 'client_react/gallery/components/SocialLinks';
import PhotoToolbar from 'client_react/gallery/components/PhotoToolbar';
import WelcomeModal from 'client_react/gallery/components/WelcomeModal';
import CartPreview from 'ts/common/components/product/CartPreview';
import ProductRender from 'ts/common/components/product/ProductRender';
import { withAngularJsTranslate, withCommon } from 'sp-ui';
import { setBaseUrl, setEvent } from 'ts/client';
import { AddToCartSidePanel, setCurrencyFn, withTheme } from 'ts/client/gallery';
import { Env, setEnv } from 'ts/common';
import { TermsAndPolicyLinks } from 'ts/common/components';
import { ProductPreview, ProductEdit } from 'ts/common/components/product';
import { PerspectiveRender } from 'ts/common/components/product/PerspectiveRender';
import {
    AlbumPageTitle,
    BottomNavSmallOnly,
    Button,
    EmptyState,
    Header,
    HomePageTitle,
    PageTitle,
    PhotoGrid,
    PhotoIndicatorList,
    PhotoOverlay,
    StudioHeading,
} from 'ts/common/components/gallery';
import GoogleOAuthRedirectPage from 'client_react/gallery/pages/GoogleOAuthRedirectPage';
import GooglePhotosExportPage from 'client_react/gallery/pages/GooglePhotosExportPage';
import LandingPage from 'client_react/gallery/pages/LandingPage';
import StorePage from 'client_react/gallery/pages/StorePage';
import spCommonModule from '../../newcommon/js/sp.common';
import spClientCommonModule from '../common/sp.client.common';
import angularSanitizeModule from 'angular-sanitize';

const spClientGalleryApp = angular.module('SPClientApp', [
    spCommonModule.name,
    spClientCommonModule,
    angularSanitizeModule
]);

spClientGalleryApp.config([
    '$sceDelegateProvider',
    '$routeProvider',
    '$locationProvider',
    'spAppDataProvider',
    'SPPhotoProvider',
    'spSvg:config',
    function spClientGalleryAppConfig(
        $sceDelegateProvider,
        $routeProvider,
        $locationProvider,
        spAppDataProvider,
        SPPhotoProvider,
        spSvgConfig
    ) {
        const release = spAppDataProvider.get('release');
        const urls = spAppDataProvider.get('urls');

        // Configure spSvg to know where its default document is
        spSvgConfig.defaultSVGDocument = `/content/${release}/svg.svg`;

        $sceDelegateProvider.resourceUrlWhitelist([
            'self',
            'http://static.shootproof.com/**',
            'https://static.shootproof.com/**',
            'http://static.*.shootproof.com/**',
            'https://static.*.shootproof.com/**',
            'https://d1icb03h9nte03.cloudfront.net/**',
            'https://d2eiu0aj1vcuc8.cloudfront.net/**',
            'https://d26jca83quavpd.cloudfront.net/**',
            'https://d2j6j20uzcmhjg.cloudfront.net/**',
            'https://dgrtyzfesrbxq.cloudfront.net/**'
        ]);

        // Use HTML5 mode.
        $locationProvider.html5Mode(true);

        $routeProvider
            .when('/', {
                template: '<landing-page />',
                componentName: 'Landing'
            })
            .when('/admin', {
                template: '<landing-page is-admin-route=" true " />',
                componentName: 'Landing'
            })
            .when('/home', {
                controller: 'Home',
                spRouteId: 'Home',
                spRoute: {
                    navigationRoot: 'Home',
                    dataContext: 'Home'
                },
                templateUrl: getPageURL('home')
            })
            .when('/view', {
                redirectTo: '/home'
            })
            .when('/home/photo/:photoId', {
                redirectTo: '/photo/:photoId'
            })
            .when('/compare', {
                controller: 'ComparePhotos',
                spRoute: {
                    navigationDisabled: false,
                    dataContext: 'Compare'
                },
                templateUrl: getPageURL('compare')
            })
            .when('/photo/:photoId', {
                controller: 'Photo',
                spRoute: {
                    navigationDisabled: true,
                    navigationRoot: 'Home',
                    parentId: 'Home',
                    dataContext: 'Home'
                },
                templateUrl: getPageURL('photo')
            })
            .when('/album/:albumId', {
                controller: 'Album',
                spRouteId: 'Album',
                spRoute: {
                    navigationRoot: 'Album',
                    // This is a special case, an album can belong to Home or Another Album
                    parentId: 'HomeOrAlbum',
                    dataContext: 'Album'
                },
                templateUrl: getPageURL('album')
            })
            .when('/album/:albumId/photo/:photoId', {
                controller: 'Photo',
                spRoute: {
                    navigationDisabled: true,
                    navigationRoot: 'Album',
                    parentId: 'Album',
                    dataContext: 'Album'
                },
                templateUrl: getPageURL('photo')
            })
            .when('/info', {
                controller: 'Info',
                spRoute: {
                    navigationRoot: 'Info'
                },
                templateUrl: getPageURL('info')
            })
            .when('/info/:sectionKey', {
                controller: 'Info',
                spRoute: {
                    navigationRoot: 'Info'
                },
                templateUrl: getPageURL('info')
            })
            .when('/favorites', {
                controller: 'Favorites',
                spRouteId: 'Favorites',
                spRoute: {
                    navigationRoot: 'Special',
                    navigationSecondary: 'Favorites',
                    dataContext: 'Favorites'
                },
                templateUrl: getPageURL('favorites')
            })
            .when('/favorites/photo/:photoId', {
                controller: 'Photo',
                spRoute: {
                    navigationDisabled: true,
                    navigationRoot: 'Special',
                    navigationSecondary: 'Favorites',
                    parentId: 'Favorites',
                    dataContext: 'Favorites'
                },
                templateUrl: getPageURL('photo')
            })
            .when('/labels', {
                controller: 'Tags',
                spRouteId: 'Labels',
                spRoute: {
                    navigationRoot: 'Special',
                    navigationSecondary: 'Labels',
                    dataContext: 'Tags'
                },
                templateUrl: getPageURL('tags')
            })
            .when('/label/:tagName', {
                controller: 'Tag',
                spRouteId: 'Label',
                spRoute: {
                    navigationRoot: 'Special',
                    navigationSecondary: 'Labels',
                    parentId: 'Labels',
                    dataContext: 'Tag'
                },
                templateUrl: getPageURL('tag')
            })
            .when('/label/:tagName/photo/:photoId', {
                controller: 'Photo',
                spRoute: {
                    navigationDisabled: true,
                    navigationRoot: 'Special',
                    navigationSecondary: 'Labels',
                    parentId: 'Label',
                    dataContext: 'Tag'
                },
                templateUrl: getPageURL('photo')
            })
            .when('/hidden', {
                controller: 'Hidden',
                spRouteId: 'Hidden',
                spRoute: {
                    navigationRoot: 'Special',
                    navigationSecondary: 'Hidden',
                    dataContext: 'Hidden'
                },
                templateUrl: getPageURL('hidden')
            })
            .when('/hidden/photo/:photoId', {
                controller: 'Photo',
                spRoute: {
                    navigationDisabled: true,
                    navigationRoot: 'Special',
                    navigationSecondary: 'Hidden',
                    parentId: 'Hidden',
                    dataContext: 'Hidden'
                },
                templateUrl: getPageURL('photo')
            })
            .when('/search', {
                controller: 'Search',
                spRouteId: 'Search',
                spRoute: {
                    navigationRoot: 'Search',
                    dataContext: 'Search'
                },
                templateUrl: getPageURL('search')
            })
            .when('/search/photo/:photoId', {
                controller: 'Photo',
                spRoute: {
                    navigationDisabled: true,
                    navigationRoot: 'Search',
                    navigationSecondary: 'Search',
                    parentId: 'Search',
                    dataContext: 'Search'
                },
                templateUrl: getPageURL('photo')
            })
            .when('/store', {
                controller: 'Store',
                spRoute: {
                    navigationRoot: 'Store'
                },
                templateUrl: getPageURL('store')
            })
            .when('/bundle/:zipBundleId', {
                controller: 'Bundle',
                spRouteId: 'Bundle',
                spRoute: {
                    musicDisabled: true
                },
                templateUrl: getPageURL('bundle')
            })
            .when('/acceptfavorites/:token', {
                controller: 'AcceptFavorites',
                spRouteId: 'AcceptFavorites',
                spRoute: {
                    musicDisabled: true
                },
                templateUrl: getPageURL('acceptfavorites')
            })
            .when('/cart', {
                controller: 'Cart',
                spRouteId: 'Cart',
                spRoute: {
                    musicDisabled: true,
                    navigationRoot: 'Cart'
                },
                templateUrl: getPageURL('cart')
            })
            .when('/google-oauth-redirect', {
                template: '<google-oauth-redirect-page />',
                componentName: 'GoogleOAuthRedirect'
            })
            .when('/google-photos-export-view', {
                spRoute: {
                    navigationDisabled: true,
                    navigationRoot: 'Home',
                    parentId: 'Home',
                    dataContext: 'GooglePhotosExport'
                },
                templateUrl: getPageURL('google-photos-export')
            })
            .when('/google-photos-export-view/:exportId', {
                spRoute: {
                    navigationDisabled: true,
                    navigationRoot: 'Home',
                    parentId: 'Home',
                    dataContext: 'GooglePhotosExport'
                },
                templateUrl: getPageURL('google-photos-export')
            })
            .otherwise({
                templateUrl: getPageURL('blank'),
                controller: [
                    '$window',
                    '$location',
                    '$httpParamSerializer',
                    function redirectTo404($window, $location, $httpParamSerializer) {
                        const eventData = spAppDataProvider.get('eventData');
                        const targetPath = $window.location.pathname;

                        if ($location.search() === undefined) {
                            return $location.url('/');
                        }

                        $window.location = `/gallery/${eventData.uriId
                            }/error/404?${$httpParamSerializer({
                                url: targetPath
                            })}`;
                    }
                ]
            });

        function getPageURL(pageName) {
            return `${urls.angularRoot}/templates/pages/${pageName}.nghtml`;
        }
    }
]);

spClientGalleryApp.config([
    'spApiHttpProvider',
    function disableSpApiHttpUnauthorizedResponseHandling(spApiHttpProvider) {
        spApiHttpProvider.disableUnauthorizedResponseHandling();
    }
]);

spClientGalleryApp.config([
    'spAppDataProvider',
    (spAppDataProvider) => {
        setEnv(
            spAppDataProvider.get('isProduction')
                ? Env.Production
                : spAppDataProvider.get('isStaging')
                    ? Env.Staging
                    : Env.Development
        );
    }
]);

import SPAddToCartService from './js/services/SPAddToCart';
import SPClientDataService from './js/services/SPClientData';
import SPClientRouteService from './js/services/SPClientRoute';
import SPFavoritePhotoService from './js/services/SPFavoritePhoto';
import SPMusicDataService from './js/services/SPMusicData';
import SPPhotoDownloadService from './js/services/SPPhotoDownload';
import SPPhotoUriService from './js/services/SPPhotoUri';
import spCanvasViewService from './js/services/spCanvasView';
import spClientCommentService from './js/services/spClientComment';
import spClientCredentialsService from './js/services/spClientCredentials';
import spComparePhotosService from './js/services/spComparePhotos';
import spEventTags from './js/services/spEventTags';
import spFreeDigitalsService from './js/services/spFreeDigitals';
import spPackageService from '../../newcommon/js/services/spPackage';
import spPackagesService from './js/services/spPackages';
import spPhotoActionsService from './js/services/spPhotoActions';
import spToast from './js/services/spToast';

spClientGalleryApp.factory('SPAddToCart', SPAddToCartService);
spClientGalleryApp.service('SPClientData', SPClientDataService);
spClientGalleryApp.factory('SPClientRoute', SPClientRouteService);
spClientGalleryApp.factory('SPFavoritePhoto', SPFavoritePhotoService);
spClientGalleryApp.service('SPMusicData', SPMusicDataService);
spClientGalleryApp.service('SPPhotoDownload', SPPhotoDownloadService);
spClientGalleryApp.factory('SPPhotoUri', SPPhotoUriService);
spClientGalleryApp.factory('spClientComment', spClientCommentService);
spClientGalleryApp.factory('spClientCredentials', spClientCredentialsService);
spClientGalleryApp.service('spCanvasView', spCanvasViewService);
spClientGalleryApp.service('spComparePhotos', spComparePhotosService);
spClientGalleryApp.factory('spEventTags', spEventTags);
spClientGalleryApp.factory('spFreeDigitals', spFreeDigitalsService);
spClientGalleryApp.factory('spPackage', spPackageService);
spClientGalleryApp.factory('spPackages', spPackagesService);
spClientGalleryApp.service('spPhotoActions', spPhotoActionsService);
spClientGalleryApp.factory('spToast', spToast);


import spPriceSheetItemSubgroupFilter from '../../studio/price_sheet/js/filters/spPriceSheetItemSubgroup.js';
import spPackageItemDetailFilter from './js/filters/spPackageItemDetail';
import spPackageCartItemDetailFilter from './js/filters/spPackageCartItemDetail';

spClientGalleryApp.filter('spPriceSheetItemSubgroup', spPriceSheetItemSubgroupFilter);
spClientGalleryApp.filter('spPackageItemDetail', spPackageItemDetailFilter);
spClientGalleryApp.filter('spPackageCartItemDetail', spPackageCartItemDetailFilter);

import spCartItemComponent from './js/components/spCartItem';
import spCollectEmailComponent from './js/components/spCollectEmail';
import spEventInfoMessageComponent from './js/components/spEventInfoMessage';
import spMarketingBannerComponent from './js/components/spMarketingBanner';
import spPackageAlbumItemTileComponent from './js/components/spPackageAlbumItemTile';
import spPackageCartItemComponent from './js/components/spPackageCartItem';
import spPackageBuilderComponent from './js/components/spPackageBuilder';
import spPackageBuilderItemComponent from './js/components/spPackageBuilderItem';
import spPackageItemTileComponent from './js/components/spPackageItemTile';
import spPackageSingleItemTileComponent from './js/components/spPackageSingleItemTile';
import spPackageTileComponent from './js/components/spPackageTile';
import spMusic from './js/components/spMusic';

spClientGalleryApp.component('spCartItem', spCartItemComponent);
spClientGalleryApp.component('spCollectEmail', spCollectEmailComponent);
spClientGalleryApp.component('spEventInfoMessage', spEventInfoMessageComponent);
spClientGalleryApp.component('spMarketingBanner', spMarketingBannerComponent);
spClientGalleryApp.component('spPackageAlbumItemTile', spPackageAlbumItemTileComponent);
spClientGalleryApp.component('spPackageCartItem', spPackageCartItemComponent);
spClientGalleryApp.component('spPackageBuilder', spPackageBuilderComponent);
spClientGalleryApp.component('spPackageBuilderItem', spPackageBuilderItemComponent);
spClientGalleryApp.component('spPackageItemTile', spPackageItemTileComponent);
spClientGalleryApp.component('spPackageSingleItemTile', spPackageSingleItemTileComponent);
spClientGalleryApp.component('spPackageTile', spPackageTileComponent);
spClientGalleryApp.component('spMusic', spMusic);

import brandDataFacade from './js/facades/brandData';
import eventDataFacade from './js/facades/eventData';
import themeDataFacade from './js/facades/themeData';
import toggleBodyScrollingFacade from './js/facades/toggleBodyScrolling';
import userStateFacade from './js/facades/userState';

spClientGalleryApp.run(brandDataFacade);
spClientGalleryApp.run(eventDataFacade);
spClientGalleryApp.run(themeDataFacade);
spClientGalleryApp.run(toggleBodyScrollingFacade);
spClientGalleryApp.run(userStateFacade);

import DigitalRuleDataStore from './js/data_stores/DigitalRule';
import EventDataStore from './js/data_stores/Event';
import EventAlbumDataStore from './js/data_stores/EventAlbum';
import EventTagDataStore from './js/data_stores/EventTag';
import GooglePhotosExportDataStore from './js/data_stores/GooglePhotosExport';
import NotificationDataStore from './js/data_stores/Notification';
import PhotoDownloadDataStore from './js/data_stores/PhotoDownload';
import PriceSheetItemDataStore from './js/data_stores/PriceSheetItem';
import ZipBundleDataStore from './js/data_stores/ZipBundle';

spClientGalleryApp.run(DigitalRuleDataStore);
spClientGalleryApp.run(EventDataStore);
spClientGalleryApp.run(EventAlbumDataStore);
spClientGalleryApp.run(EventTagDataStore);
spClientGalleryApp.run(GooglePhotosExportDataStore);
spClientGalleryApp.run(NotificationDataStore);
spClientGalleryApp.run(PhotoDownloadDataStore);
spClientGalleryApp.run(PriceSheetItemDataStore);
spClientGalleryApp.run(ZipBundleDataStore);

spClientGalleryApp.run([
    '$window',
    'SPSupport',
    function handleUnsupportedBrowsers($window, SPSupport) {
        if (!SPSupport.isBrowserSupported()) {
            $window.location.href = '/unsupported';
        }
    }
]);

spClientGalleryApp.run([
    '$rootScope',
    '$location',
    'spHiddenAlbum',
    function handleAuthenticationCheck($rootScope, $location, spHiddenAlbum) {
        // If the request is for any page below the landing page
        // and any credentials are needed, move the user to the
        // landing page.
        const path = $location.path() || '';
        let needsToAuthenticate = false;

        if (
            path !== '/' &&
            path !== '/admin' &&
            path.indexOf('/acceptfavorites/') !== 0 &&
            path.indexOf('/bundle/') !== 0
        ) {
            if ($rootScope.eventData.settings.preRegister) {
                // If the Event is in pre-registration mode, and the visitor is
                // either the Event Contact, or in Admin mode, they can proceed
                // normally to view the Gallery.  Otherwise, any non-Event Contact
                // should be bounced to landing.
                if (
                    !$rootScope.userState.isAdminModeEnabled &&
                    !$rootScope.userState.isVisitorEventContact
                ) {
                    needsToAuthenticate = true;
                }
            } else {
                // If the Event isn't in pre-registration mode, the end user
                // must have email and/or password if they're required.  If
                // they don't, bounce to landing.
                if (
                    ($rootScope.eventData.settings.requireEmail &&
                        !$rootScope.userState.hasUserEmail) ||
                    ($rootScope.eventData.settings.requirePassword &&
                        !$rootScope.userState.hasEventPassword)
                ) {
                    needsToAuthenticate = true;
                }
            }
        }

        if (spHiddenAlbum.isInitialViewAHiddenAlbum || spHiddenAlbum.isInFlow()) {
            needsToAuthenticate = false;
        }

        if (needsToAuthenticate) {
            $rootScope.preAuthTargetUrl = $location.url();

            $rootScope.clearAuthTargetUrl = function clearAuthTargetUrl() {
                delete $rootScope.preAuthTargetUrl;
            };

            $location.url('');
        }
    }
]);

spClientGalleryApp.run([
    '$rootScope',
    '$location',
    function addNavigationFunction($rootScope, $location) {
        $rootScope.navigate = function navigate(path) {
            $rootScope.$evalAsync(() => {
                $location.url(path);
            });
        };
    }
]);

spClientGalleryApp.run([
    '$rootScope',
    'spClientCredentials',
    function addEmailFunction($rootScope, spClientCredentials) {
        $rootScope.saveEmail = function saveEmail(email) {
            spClientCredentials.set({ email });
        };
    }
]);

spClientGalleryApp.run([
    '$rootScope',
    function addPopulateUserState($rootScope) {
        $rootScope.populateUserState = function populateUserState(response) {
            if (typeof response.userState.hasUserEmail === 'boolean') {
                $rootScope.userState.hasUserEmail = response.userState.hasUserEmail;
            }

            if (typeof response.userState.hasEventPassword === 'boolean') {
                $rootScope.userState.hasEventPassword = response.userState.hasEventPassword;
            }

            if (typeof response.userState.isVisitorEventContact === 'boolean') {
                $rootScope.userState.isVisitorEventContact =
                    response.userState.isVisitorEventContact;
            }

            if (typeof response.userState.isEventContact === 'boolean') {
                $rootScope.userState.isEventContact = response.userState.isEventContact;
            }

            if (typeof response.userState.isAdminModeEnabled === 'boolean') {
                $rootScope.userState.isAdminModeEnabled = response.userState.isAdminModeEnabled;
            }

            if (typeof response.userState.numFavorites === 'number') {
                $rootScope.userState.numFavorites = response.userState.numFavorites;
            }

            if (typeof response.userState.numCartItems === 'number') {
                $rootScope.userState.numCartItems = response.userState.numCartItems;
            }

            if (typeof response.userState.cartPreDiscountSubtotal === 'number') {
                $rootScope.userState.cartPreDiscountSubtotal =
                    response.userState.cartPreDiscountSubtotal;
            }

            if (typeof response.userState.cartPreDiscountSubtotalFormatted === 'string') {
                $rootScope.userState.cartPreDiscountSubtotalFormatted =
                    response.userState.cartPreDiscountSubtotalFormatted;
            }

            if (typeof response.userState.cartPostDiscountSubtotal === 'number') {
                $rootScope.userState.cartPostDiscountSubtotal =
                    response.userState.cartPostDiscountSubtotal;
            }

            if (typeof response.userState.cartPostDiscountSubtotalFormatted === 'string') {
                $rootScope.userState.cartPostDiscountSubtotalFormatted =
                    response.userState.cartPostDiscountSubtotalFormatted;
            }

            if (typeof response.userState.cartHasDiscounts === 'boolean') {
                $rootScope.userState.cartHasDiscounts = response.userState.cartHasDiscounts;
            }
        };
    }
]);

spClientGalleryApp.run([
    'currencyFilter',
    'spAppData',
    (currencyFilter, spAppData) => {
        setBaseUrl(spAppData.get('urls').apiRoot);
        setCurrencyFn(currencyFilter);
        setEvent({
            brandId: spAppData.get('brandData').id,
            ...spAppData.get('eventData')
        });
    }
]);

spClientGalleryApp.run([
    '$rootScope',
    '$route',
    ($rootScope, $route) => {
        $rootScope.$on('reloadRoute', function reloadCurrentRoute() {
            $route.reload();
        });
    }
]);

spClientGalleryApp.component(
    'termsAndPolicyLinks',
    react2angular(
        withAngularJsTranslate(withTheme(TermsAndPolicyLinks)),
        ['isComparing', 'isPackageBuilderOpen'],
        ['spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'addToCartSidePanel',
    react2angular(
        withAngularJsTranslate(withTheme(AddToCartSidePanel)),
        [
            'brandCurrencyCode',
            'getPathContext',
            'getPhotoUrl',
            'isSubjectToGdpr',
            'selectPackage',
            'setIsAddToCartSidePanelOpen',
            'withSinglePhotoOpen',
            'withAllPhotosOpen',
            'updateUserCartState'
        ],
        ['spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'photoHeader',
    react2angular(
        withTheme(PhotoHeader),
        ['parentRouteName', 'parentUri', 'photo', 'photoActions'],
        ['spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'photoIndicatorList',
    react2angular(
        withCommon(PhotoIndicatorList),
        ['size', 'isBlackAndWhite', 'isInCart', 'isFavorite', 'isHidden', 'isLabeled'],
        ['spEventBus']
    )
);

spClientGalleryApp.component(
    'photoOverlay',
    react2angular(
        withTheme(PhotoOverlay),
        [
            'isBlackAndWhite',
            'isComparing',
            'isDownloadPossible',
            'isFavorite',
            'isHidden',
            'isInCart',
            'isLabeled',
            'isPackageBuilderOpen',
            'isReadyToCompare',
            'isSelected',
            'onClick',
            'photo',
            'photoActions'
        ],
        ['spEventBus', 'translateFilter', '$rootScope']
    )
);

spClientGalleryApp.component(
    'photoToolbar',
    react2angular(
        withTheme(PhotoToolbar),
        [
            'isBlackAndWhite',
            'isComparing',
            'isDownloadPossible',
            'isFavorite',
            'isHidden',
            'isLabeled',
            'isPackageBuilderOpen',
            'isReadyToCompare',
            'isSelected',
            'onClick',
            'photo',
            'photoActions',
            'requiresDownloadDestination',
            'updateCount'
        ],
        ['spEventBus', 'translateFilter', '$rootScope']
    )
);

spClientGalleryApp.component(
    'galleryButton',
    react2angular(
        withTheme(Button),
        ['text', 'variant', 'size', 'fullWidth', 'onClick'],
        ['spEventBus']
    )
);

spClientGalleryApp.component(
    'bottomNav',
    react2angular(
        withTheme(BottomNavSmallOnly),
        [
            'cartCount',
            'favoritesCount',
            'homeLinkHref',
            'homeLinkLabel',
            'isAboutContactVisible',
            'isAboutMessageVisible',
            'isAboutPricingVisible',
            'isAboutVisible',
            'isAdminModeEnabled',
            'isCartVisible',
            'isHiddenPhotosVisible',
            'isHomeSecondaryNavVisible',
            'isLabeledPhotosVisible',
            'isPackageBuilderOpen',
            'isSearchVisible',
            'isStoreEnabled',
            'reloadRoute',
            'selectedRoute',
            'selectedUri'
        ],
        ['spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'galleryHeader',
    react2angular(
        withTheme(Header),
        [
            'cartCount',
            'favoritesCount',
            'homeLinkHref',
            'homeLinkLabel',
            'isAboutContactVisible',
            'isAboutMessageVisible',
            'isAboutPricingVisible',
            'isAboutVisible',
            'isAdminModeEnabled',
            'isBackNavVisible',
            'isCartVisible',
            'isHidden',
            'isHiddenPhotosVisible',
            'isHomeSecondaryNavVisible',
            'isLabeledPhotosVisible',
            'isPackageBuilderOpen',
            'isScrolledDown',
            'isScrollingDown',
            'isSearchVisible',
            'isStoreEnabled',
            'isToolbarHidden',
            'parentRouteName',
            'parentUri',
            'portfolioWebsiteHref',
            'selectedRoute',
            'selectedUri',
            'shareCopyLinkUrl',
            'shareUrls',
            'studioName',
            'reloadRoute',
            'requiresDownloadDestination',
            'toolbarActions'
        ],
        ['spEventBus', 'spFreeDigitals', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'compareHeader',
    react2angular(
        withTheme(CompareHeader),
        [
            'isComparing',
            'isReadyToCompare', 
            'isScrolledDown',
            'isScrollingDown',
            'onComparePhotosClick',
            'onExitClick'
        ],
        ['spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'studioHeading',
    react2angular(
        withTheme(StudioHeading),
        ['text', 'variant'],
        ['spEventBus']
    )
);

spClientGalleryApp.component(
    'googleOauthRedirectPage',
    react2angular(withTheme(GoogleOAuthRedirectPage), [], ['spEventBus'])
);

spClientGalleryApp.component(
    'googlePhotosExportPage',
    react2angular(
        GooglePhotosExportPage,
        ['brandName', 'googlePhotosExport'],
        ['spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'landingPage',
    react2angular(
        LandingPage,
        ['isAdminRoute'],
        ['spEventBus', 'spAppData', '$rootScope', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'storePage',
    react2angular(
        withAngularJsTranslate(withTheme(StorePage)),
        [
            'brandCurrencyCode',
            'pathContextBeforeRouteChange',
            'isAdminModeEnabled',
            'isCartVisible',
            'isLabeledPhotosVisible',
            'isStoreEnabled',
            'isSubjectToGdpr',
            'updateUserCartState',
            'selectPackage'
        ],
        ['spEventBus', 'translateFilter', '$rootScope']
    )
);

spClientGalleryApp.component(
    'comparePage',
    react2angular(
        withTheme(ComparePage),
        [
            'backNavHref',
            'isDownloadPossible',
            'isLeftPhotoBlackAndWhite',
            'isLeftPhotoFavorite',
            'isLeftPhotoHidden',
            'isLeftPhotoLabeled',
            'isRightPhotoBlackAndWhite',
            'isRightPhotoFavorite',
            'isRightPhotoHidden',
            'isRightPhotoLabeled',
            'leftPhoto',
            'photoActions',
            'requiredDestinationSelectionPhotoId',
            'rightPhoto'
        ],
        ['spEventBus', 'spFreeDigitals', 'translateFilter', '$rootScope']
    )
);

spClientGalleryApp.component(
    'productEdit',
    react2angular(withCommon(ProductEdit), [
        'disableRotating',
        'item',
        'frame',
        'onUpdate',
        'photo',
        'photoUrl'
    ])
);


spClientGalleryApp.component(
    'cartPreview',
    react2angular(withCommon(CartPreview), ['crop', 'image', 'priceSheetItemId'])
);


spClientGalleryApp.component(
    'productRender',
    react2angular(withCommon(ProductRender), [
        'crop',
        'image',
        'hideBackdrop',
        'priceSheetItem',
        'productPreviewProps',
        'variant'
    ])
);


spClientGalleryApp.component(
    'productPreview',
    react2angular(withCommon(ProductPreview), [
        'photo',
        'item',
        'photoUrl',
        'variant',
        'previewEffects'
    ])
);

spClientGalleryApp.component(
    'perspectiveRender',
    react2angular(withCommon(PerspectiveRender), [
        'crop',
        'isBlackAndWhite',
        'isAcrylicBlock',
        'photo',
        'photoUrl',
        'exhibition'
    ])
);

spClientGalleryApp.component(
    'digitalDownloadPinForm',
    react2angular(
        withTheme(DigitalDownloadPinForm),
        ['hasPin', 'onSubmit'],
        ['spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'digitalDownloadRulesForm',
    react2angular(
        withTheme(DigitalDownloadRulesForm),
        ['canExportToGoogle', 'onClose', 'onSubmit', 'radioOptions'],
        ['$rootScope', 'spEventBus', 'spFreeDigitals', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'digitalDownloadRulesHeader',
    react2angular(
        withTheme(DigitalDownloadRulesHeader),
        ['hasPin', 'isDownloadAlbum', 'isDownloadAll', 'isDownloadPhoto'],
        ['spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'digitalDownloadEmailCredentialsModal',
    react2angular(
        withTheme(DigitalDownloadEmailCredentialsModal),
        [
            'isDownloadAlbum',
            'isDownloadAll',
            'isDownloadPhoto',
            'isSubjectToGdpr',
            'onClose',
            'onSubmit'
        ],
        ['$rootScope', 'spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'albumList',
    react2angular(withTheme(AlbumList), ['albums', 'hideCount'], ['spEventBus', 'translateFilter'])
);

spClientGalleryApp.component(
    'labelList',
    react2angular(
        withTheme(LabelList),
        ['getLabelCaption', 'getPhotoUrl', 'labels'],
        ['spEventBus']
    )
);

spClientGalleryApp.component(
    'bundleFileList',
    react2angular(
        withTheme(BundleFileList),
        ['files', 'onClickFile'],
        ['spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'packageAddToCartModal',
    react2angular(
        withTheme(PackageAddToCartModal),
        ['isPackageBeingEdited', 'primaryButtonAction', 'secondaryButtonAction'],
        ['$rootScope', 'spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'exitPackageBuilderModal',
    react2angular(
        withTheme(ExitPackageBuilderModal),
        ['primaryButtonAction', 'secondaryButtonAction'],
        ['$rootScope', 'spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'albumPasswordForm',
    react2angular(
        withTheme(AlbumPasswordForm),
        ['errorMessage', 'isInvalid', 'onClose', 'onChangePassword', 'onSubmit'],
        ['spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'emptyState',
    react2angular(
        withTheme(EmptyState),
        ['page'],
        ['spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'welcomeModal',
    react2angular(
        withTheme(WelcomeModal),
        ['bodyText', 'buttonAction', 'headerText', 'videoUrl'],
        ['spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'adminModal',
    react2angular(
        withTheme(AdminModal),
        ['errorMessage', 'isInvalid', 'onClose', 'onChangePin', 'onSubmit'],
        ['spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'emptyCartModal',
    react2angular(
        withTheme(EmptyCartModal),
        ['primaryButtonAction', 'secondaryButtonAction'],
        ['$rootScope', 'spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'labelModalHeader',
    react2angular(
        withTheme(LabelModalHeader),
        ['onNavigate'],
        ['$rootScope', 'spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'labelModalFooter',
    react2angular(
        withTheme(LabelModalFooter),
        ['onClose'],
        ['$rootScope', 'spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'socialLinks',
    react2angular(
        withTheme(SocialLinks),
        ['facebookLink', 'instagramLink', 'pinterestLink', 'twitterLink'],
        ['spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'collectEmailCredentialsModal',
    react2angular(
        withTheme(CollectEmailCredentialsModal),
        ['isCollectFavoritesEmail', 'isRecoverCart', 'isRecoverFavorites', 'isSubjectToGdpr', 'onClose', 'onSubmit', 'isInvalid', 'errorMessage', 'onChangeEmail'],
        ['$rootScope', 'spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'studioMessage',
    react2angular(
        withTheme(StudioMessage),
        ['headerText', 'videoUrl', 'bodyText', 'isInModal'],
        ['spEventBus']
    )
);

spClientGalleryApp.component(
    'pageTitle',
    react2angular(
        withTheme(PageTitle),
        ['caption', 'coverPhoto', 'headingText'],
        ['spEventBus']
    )
);

spClientGalleryApp.component(
    'albumPageTitle',
    react2angular(
        withTheme(AlbumPageTitle),
        ['albumCount', 'coverPhoto', 'headingText', 'photoCount'],
        ['spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'homePageTitle',
    react2angular(
        withTheme(HomePageTitle),
        ['albumCount', 'coverPhoto', 'headingText', 'photoCount'],
        ['spEventBus', 'translateFilter']
    )
);

spClientGalleryApp.component(
    'photoGrid',
    react2angular(
        withTheme(PhotoGrid),
        [
            'getPhotoUrl',
            'isComparing',
            'isDownloadPossible',
            'isPackageBuilderOpen',
            'isPhotoSelected',
            'isReadyToCompare',
            'layoutType',
            'onClickPhoto',
            'photos',
            'photoActions',
            'requiredDestinationSelectionPhotoId',
            'scrollToTargetPhoto',
            'thumbnailGutterWidth',
            'thumbnailSize',
            'updateCount'
        ],
        ['$rootScope', 'spEventBus', 'spFreeDigitals', 'translateFilter']
    )
);

import AcceptFavoritesController from './js/controllers/AcceptFavorites';
import AlbumController from './js/controllers/Album';
import BundleController from './js/controllers/Bundle';
import CartController from './js/controllers/Cart';
import ClientRootController from './js/controllers/ClientRoot';
import ComparePhotosController from './js/controllers/ComparePhotos';
import GooglePhotosExportController from './js/controllers/GooglePhotosExport';
import FavoritesController from './js/controllers/Favorites';
import HiddenController from './js/controllers/Hidden';
import HomeController from './js/controllers/Home';
import InfoController from './js/controllers/Info';
import PhotoController from './js/controllers/Photo';
import SearchController from './js/controllers/Search';
import StoreController from './js/controllers/Store';
import TagController from './js/controllers/Tag';
import TagsController from './js/controllers/Tags';

spClientGalleryApp.controller('AcceptFavorites', AcceptFavoritesController);
spClientGalleryApp.controller('Album', AlbumController);
spClientGalleryApp.controller('Bundle', BundleController);
spClientGalleryApp.controller('Cart', CartController);
spClientGalleryApp.controller('ClientRoot', ClientRootController);
spClientGalleryApp.controller('ComparePhotos', ComparePhotosController);
spClientGalleryApp.controller('Favorites', FavoritesController);
spClientGalleryApp.controller('GooglePhotosExport', GooglePhotosExportController);
spClientGalleryApp.controller('Hidden', HiddenController);
spClientGalleryApp.controller('Home', HomeController);
spClientGalleryApp.controller('Info', InfoController);
spClientGalleryApp.controller('Photo', PhotoController);
spClientGalleryApp.controller('Search', SearchController);
spClientGalleryApp.controller('Store', StoreController);
spClientGalleryApp.controller('Tag', TagController);
spClientGalleryApp.controller('Tags', TagsController);

import CaptureCredentialsController from './js/controllers/modals/free_digitals/CaptureCredentials';
import SelectDigitalRuleController from './js/controllers/modals/free_digitals/SelectDigitalRule';

spClientGalleryApp.controller('CaptureCredentials', CaptureCredentialsController);
spClientGalleryApp.controller('SelectDigitalRule', SelectDigitalRuleController);

import AddToCartController from './js/controllers/partials/AddToCart';
import AdminAuthController from './js/controllers/partials/AdminAuth';
import AlbumAuthController from './js/controllers/partials/AlbumAuth';
import cartPhotoItemCropController from './js/controllers/partials/cartPhotoItemCrop';
import CollectEmailController from './js/controllers/partials/CollectEmail';
import FreeDigitalsHeadingController from './js/controllers/partials/FreeDigitalsHeading';
import GalleryController from './js/controllers/partials/Gallery';
import InfoContactController from './js/controllers/partials/InfoContact';
import InfoMessageController from './js/controllers/partials/InfoMessage';
import InfoPricingController from './js/controllers/partials/InfoPricing';
import KeyboardHelpController from './js/controllers/partials/KeyboardHelp';
import NavigationController from './js/controllers/partials/Navigation';
import PackageSuccessController from './js/controllers/partials/PackageSuccess';
import PackageWarningController from './js/controllers/partials/PackageWarning';
import SelectPackageController from './js/controllers/partials/SelectPackage';

spClientGalleryApp.controller('AddToCart', AddToCartController);
spClientGalleryApp.controller('AdminAuth', AdminAuthController);
spClientGalleryApp.controller('AlbumAuth', AlbumAuthController);
spClientGalleryApp.controller('cartPhotoItemCrop', cartPhotoItemCropController);
spClientGalleryApp.controller('CollectEmail', CollectEmailController);
spClientGalleryApp.controller('FreeDigitalsHeading', FreeDigitalsHeadingController);
spClientGalleryApp.controller('Gallery', GalleryController);
spClientGalleryApp.controller('InfoContact', InfoContactController);
spClientGalleryApp.controller('InfoMessage', InfoMessageController);
spClientGalleryApp.controller('InfoPricing', InfoPricingController);
spClientGalleryApp.controller('KeyboardHelp', KeyboardHelpController);
spClientGalleryApp.controller('Navigation', NavigationController);
spClientGalleryApp.controller('PackageSuccess', PackageSuccessController);
spClientGalleryApp.controller('PackageWarning', PackageWarningController);
spClientGalleryApp.controller('SelectPackage', SelectPackageController);

import spFilmstripDirective from './js/directives/spFilmstrip';
import spCanvasViewDirective from './js/directives/spCanvasView';
import spPhotoCropDirective from './js/directives/spPhotoCrop';
import spPhotoCropViewDirective from './js/directives/spPhotoCropView';
import spPhotoViewDimensionsDirective from './js/directives/spPhotoViewDimensions';
import spPreserveScrollPositionDirective from './js/directives/spPreserveScrollPosition';
import spSlideshowDirective from './js/directives/spSlideshow';
import spSlideshowPhotoDirective from './js/directives/spSlideshowPhoto';

spClientGalleryApp.directive('spFilmstrip', spFilmstripDirective);
spClientGalleryApp.directive('spCanvasView', spCanvasViewDirective);
spClientGalleryApp.directive('spPhotoCrop', spPhotoCropDirective);
spClientGalleryApp.directive('spPhotoCropView', spPhotoCropViewDirective);
spClientGalleryApp.directive('spPhotoViewDimensions', spPhotoViewDimensionsDirective);
spClientGalleryApp.directive('spPreserveScrollPosition', spPreserveScrollPositionDirective);
spClientGalleryApp.directive('spSlideshow', spSlideshowDirective);
spClientGalleryApp.directive('spSlideshowPhoto', spSlideshowPhotoDirective);
